







































































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class ActivationList extends Vue {
  public user_id = "";
  public list: any[] = []; // 数据的列表
  public refresh = 0; // 页面允许刷新（为了list的改变）

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.list = [];
    this.refresh++;
  }
}
